import React from "react"

import StandardTemplate from "templates/StandardTemplate"
import { Divider, SEO } from "components"

const NotFoundPage = () => (
  <StandardTemplate>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
    <a href="/">
      <p>Go to home page</p>
    </a>
    <Divider />
  </StandardTemplate>
)

export default NotFoundPage
